.loader-container {
  width: 400px;
  padding: 20px;
}
.inner-loader-container {
  text-align: center;
}
.loader-text {
  color: #5f951a;
  font-weight: bold;
}
