.header-container {
  background-color: #5f951a;
}

.slot-item-container {
  border: 1px solid rgba(0, 105, 255, 0.5);
}

/* .header-text-team::after {
  content: 'DocMantra';
} */

.no-slot-date-text {
  color: #17a2b8;
}

.button-prev:hover,
.button-next:hover {
  background-color: rgba(60, 151, 232, 0.7);
}

.weekHeader {
  color: black;
  padding: 10px;
  background-color: rgba(60, 151, 232, 0.2);
}

.selectedDate {
  color: white;
  background-color: #0060e6;
}

.selectedDate:hover {
  color: white;
  background-color: #0060e6;
}

.month-calendar-indicator {
  background-color: #3c97e8;
}

@media (max-width: 576px) {
  .weekHeader {
    color: black;
    padding: 5px;
    background-color: rgba(60, 151, 232, 0.2);
  }
}
