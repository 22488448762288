.inner-success-container {
  text-align: center;
}
.success-image {
  width: 80px;
  height: auto;
}
.success-text {
  color: #5f951a;
  font-weight: bold;
}
