.inner-error-container {
  text-align: center;
}
.error-image {
  width: 500px;
  height: auto;
}
.error-text {
  color: #17a2b8;
  font-weight: bold;
}
